/**
 * 2022-08-03
 * 合併中華 問卷_20220610-003.zip中
 * crm-survey-react_tele專案中
 * Templates/Unauthenticated.js
 * 設計站台無此頁面
 * 後續程式碼分離時會用到
 * 主要用於將大部分的設計站台路徑指為Unauthenticated
 * 讓填寫站台無法訪問
 */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Widget, { Color, FontSize } from "../Components/Widget";
import * as Ant from "antd";
import { navigate } from "gatsby";

import chtLogo from "../images/CHTLogo_color.svg";

function Unauthenticated({
  title,
  topImg,
  btnText,
  btnLink,
  preview,
  ...props
}) {
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    if (!preview) {
      if (btnLink) {
        window.location.replace(btnLink);
      } else {
        onClose();
      }
    }
  };

  const onClose = () => {
    // window.opener = null;
    // window.open("", "_self");
    // window.close();
    setLoading(true);
    navigate("https://www.cht.com.tw");
  };

  if (loading) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "fixed",
          zIndex: "999999",
        }}
      >
        <Ant.Spin />
      </div>
    );
  }

  return (
    <Wrapper>
      <Header />
      <Main>
        <div className="banner">
          {topImg ? (
            <img src={topImg} alt="中華電信問卷" />
          ) : (
            <picture>
              <source
                media="(min-width: 824px)"
                srcset="../images/EndPage_Web.png"
              />
              <img src="../images/EndPage_Mobile.png" alt="中華電信問卷" />
            </picture>
          )}
        </div>
        <div className="title">
          認證失敗或無此權限
          <br />
          您的帳號無法通過驗證，請聯繫客服確認。
        </div>
      </Main>
      <Footer>
        <img src={chtLogo} alt="中華電信" />
      </Footer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  background-color: #f4faff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const Header = styled.div`
  display: none;
`;

const Main = styled.div`
  display: block;
  width: 100%;
  max-width: 800px;
  height: auto;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  margin-top: 40px;
  @media screen and (max-width: 823px) {
    min-height: 532px;
    margin-top: 0;
    border-radius: 0;
  }
  .banner {
    width: 800px;
    /* min-height: 227px; */
    overflow: hidden;
    display: block;
    /* border: solid 1px #cccccc; */
    /* background-color: #cccccc; */
    @media screen and (max-width: 823px) {
      width: 100%;
      height: 0px;
      padding-bottom: calc(100% * (227 / 800));
    }
    @media screen and (max-width: 639px) {
      padding-bottom: calc(100% * (151 / 375));
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  .title {
    text-align: center;
    font-size: ${FontSize.largeTitle}px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5rem;
    letter-spacing: 0.48px;
    text-align: center;
    color: ${Color.ChtBlue_8};
    padding: 42px 36px 42px 36px;
    @media screen and (max-width: 823px) {
      padding: 20px 20px 0px;
      letter-spacing: 0.32px;
      line-height: 1.33rem;
    }
  }
  .closeBtn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 36px 36px 60px;
    @media screen and (max-width: 823px) {
      padding-top: 96px;
      padding-bottom: 204px;
    }
    .btn {
      min-width: 240px;
      height: 48px;
      box-shadow: 1px 1px 0.5px 0 rgba(0, 20, 37, 0.1);
      font-size: ${FontSize.head}px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5rem;
      letter-spacing: 2px;
      text-align: center;
      position: relative;
      @media screen and (max-width: 823px) {
        min-width: 98px;
        height: 40px;
      }
    }
    .btn-blue {
      border-color: ${Color.ChtBlue_5};
      background-color: ${Color.ChtBlue_5};
      color: #ffffff;
      &:hover {
        border-color: ${Color.ChtBlue_4};
        background-color: ${Color.ChtBlue_4};
      }
      &:active {
        border-color: ${Color.ChtBlue_8};
        background-color: ${Color.ChtBlue_8};
      }
      &:focus {
        /* -webkit-box-shadow: 0 0 4px 2px ${Color.ChtBlue_5};
        -moz-box-shadow: 0 0 4px 2px ${Color.ChtBlue_5};
        box-shadow: 0 0 4px 2px ${Color.ChtBlue_5};
        border: solid 1px ${Color.ChtBlue_5}; */
        border-color: #0691ff;
        background-color: #0691ff;
        &::after {
          position: absolute;
          width: calc( 100% - 2px );
          height: calc( 100% - 2px );
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          content: '';
          display: block;
          border: solid 1px #f4f6fc;
          background-color: transparent;
        }
      }
      &[disabled] {
        background-color: ${Color.Black_15};
        border-color: transparent;
        color: ${Color.Black_45};
      }
    }
  }
`;

const Footer = styled.div`
  width: 100%;
  max-width: 800px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 110px;
    height: auto;
  }
  @media screen and (max-width: 832px) {
    height: 75px;
    img {
      width: 80px;
    }
  }
`;

export default Unauthenticated;
